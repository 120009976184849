.compose-form {
  .compose-form__datetime-wrapper {
    .datetime__schedule,
    .datetime__expire {
      border-top: 1px solid darken($simple-background-color, 8%);
      padding: 10px;

      .datetime-dropdown {
        display: flex;

        .datetime-dropdown__input {
          flex: 1 1 auto;
        }

        .datetime-dropdown__input-invalid {
          background-color: lighten($error-value-color, 35%);
        }

        .datetime-dropdown__calendar-icon,
        select {
          flex: 0 0 auto;
  
          &:focus {
            border-color: $highlight-text-color;
          }
        }

        .react-datepicker-wrapper,
        .react-datepicker__input-container {
          display: flex;
        }

        .react-datepicker-wrapper {
          padding: 0 4px 0 0;
        }
      }
    }

    .datetime__expires-indicator {
      color: $inverted-text-color;
      background: lighten($ui-primary-color, 8%);
      border-top: 1px solid darken($simple-background-color, 8%);
      padding: 10px;
      font-size: 13px;
      font-weight: 400;
      display: flex;
      column-gap: 4px;

      .datetime__expires-indicator__icon {
        flex: 0 0 auto;
      }

      .datetime__expires-indicator__message {
        flex: 1 1 auto;
      }
    }

    .datetime__category {
      margin-bottom: 4px;
    }

    .datetime-action {
      display: flex;

      .radio-button {
        flex: 1 1 auto;
      }
    }

    input {
      box-sizing: border-box;
      font-size: 14px;
      color: $inverted-text-color;
      border: 1px solid darken($simple-background-color, 14%);
      padding: 6px 10px;
      border-radius: 4px 0 0 4px;
      min-width: 0;
      margin-left: 0;
      margin-right: 0;
    }

    select {
      appearance: none;
      box-sizing: border-box;
      font-size: 14px;
      color: $inverted-text-color;
      display: inline-block;
      width: 30px;
      outline: 0;
      font-family: inherit;
      background: $simple-background-color url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(darken($simple-background-color, 14%))}'/></svg>") no-repeat right 8px center / auto 16px;
      border: 1px solid darken($simple-background-color, 14%);
      border-radius: 0 4px 4px 0;
      padding: 6px 10px;
      text-indent: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  
    .icon-button.disabled {
      color: darken($simple-background-color, 14%);
    }
  } 
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  width: 0;
}
